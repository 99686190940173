import { render, staticRenderFns } from "./fullBleed.vue?vue&type=template&id=d9bb4ee6&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeader: require('/home/runner/work/portperrypedals/portperrypedals/components/TheHeader.vue').default,TheFooter: require('/home/runner/work/portperrypedals/portperrypedals/components/TheFooter.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VApp,VDivider,VMain})
