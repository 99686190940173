import socialImage from "~/assets/images/social-logo.png";

export default ({ $config, route }, inject) => {
  const generateMetaTags = ({
    title = "Port Perry Pedals",
    description = "Port Perry Pedals is a charitable cycling event based in " +
      "Port Perry, ON. that was founded in 2021.",
    previewImage = socialImage,
    ogType = "website",
    createdAt = null,
  } = {}) => {
    let url;
    try {
      url = new URL(route.path, $config.baseURL).toString();
    } catch (e) {
      url = route.path;
    }

    let data = [
      {
        vmid: "og:image",
        content: previewImage,
        property: "og:image",
      },
      {
        vmid: "og:type",
        content: ogType,
        property: "og:type",
      },
      {
        vmid: "og:url",
        content: url,
        property: "og:url",
      },
      {
        vmid: "twitter:card",
        name: "twitter:card",
        content: "summary_large_image",
      },
      {
        vmid: "twitter:site",
        name: "twitter:site",
        content: "@portperrypedals",
      },
    ];

    if (description) {
      data = [
        ...data,
        {
          hid: "description",
          name: "description",
          content: description,
        },
        {
          vmid: "og:description",
          content: description,
          property: "og:description",
        },
      ];
    }
    if (title) {
      data = [
        ...data,
        {
          vmid: "og:title",
          content: title,
          property: "og:title",
        },
      ];
    }

    if (createdAt) {
      data.push({
        vmid: "og:article:published_time",
        property: "og:article:published_time",
        content: createdAt,
      });
    }
    return data;
  };

  inject("generateMetaTags", generateMetaTags);
};
