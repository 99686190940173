
import { mdiMenu } from "@mdi/js";

import logo from "~/assets/images/dark-logo-reversed.png";

export default {
  data() {
    return {
      drawer: false,
      logo,
      mdiMenu,
      navigation: this.$store.getters["navigation/main"],
    };
  },

  computed: {
    listMarginTop() {
      return this.$vuetify.breakpoint.xl ? "inherit" : "110px";
    },

    maxImageWidth() {
      const sizes = {
        xs: "240px",
        sm: "290px",
      };
      return sizes[this.$vuetify.breakpoint.name] || "380px";
    },
  },
};
