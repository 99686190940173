import { render, staticRenderFns } from "./TheHeader.vue?vue&type=template&id=c39794e6&"
import script from "./TheHeader.vue?vue&type=script&lang=js&"
export * from "./TheHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SocialMediaLinks: require('/home/runner/work/portperrypedals/portperrypedals/components/SocialMediaLinks.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VAppBar,VAppBarNavIcon,VFooter,VIcon,VImg,VList,VListItem,VListItemGroup,VListItemIcon,VListItemTitle,VNavigationDrawer})
