
import { mdiEmail, mdiFacebook, mdiInstagram, mdiTwitter } from "@mdi/js";

export default {
  name: "SocialMediaLinks",

  data() {
    return {
      socials: [
        {
          title: "Facebook",
          icon: mdiFacebook,
          url: "https://www.facebook.com/portperrypedals/",
        },
        {
          title: "Instagram",
          icon: mdiInstagram,
          url: "https://instagram.com/portperrypedals/",
        },
        {
          title: "Twitter",
          icon: mdiTwitter,
          url: "https://instagram.com/portperrypedals/",
        },
        {
          email: "Email",
          icon: mdiEmail,
          url: "mailto:info@portperrypedals.ca",
        },
      ],
    };
  },
};
